import * as React from 'react';
import Seo from '../components/seo';
import Layout from '../components/layout';
import GallerySection from '../components/GallerySection/GallerySection';
import FeatureSectionLeft from '../components/FeatureSectionLeft/FeatureSectionLeft';
import FeatureSectionRight from '../components/FeatureSectionRight/FeatureSectionRight';
import FeatureSectionGridThree from '../components/FeatureSectionGridThree/FeatureSectionGridThree';

const WazaiTopPage = ({ data, location }) => {
  return (
    <div>
      <Layout>
        <FeatureSectionLeft
          imageSrc="/wazai/section1.jpg"
          imageAlt="こだわりのお野菜"
          title="こだわりのお野菜"
          description={`和菜屋 北九州では、地元で採れた旬の食材を豊富に使い、素材にこだわった家庭料理をご提供します。
        メニューは毎日の仕入れに合わせて日替りでご用意。郷土料理のぬか炊き、昔ながらのお漬物、お楽しみの甘味なども。
        ​​河内豆腐、築上鶏卵の新鮮卵、平井商店の杵つき餅・おはぎ、など地元で人気の食材、小倉南区合馬産の新鮮野菜、厳選したお酒なども販売しています。
        
        （金田店・木町店とも無料駐車場完備）
        
        また、お惣菜は早朝から手づくりしており、出来上がり次第、順次提供させていただいています。その為、お越しいただいた時間に十分な種類の商品が揃っていないこともあるかもしれませんが、ご理解いただけますと幸いです。
        `}
          sectionId="section1"
        />
        <GallerySection
          title="取り扱い惣菜"
          description={`このような惣菜を取り扱っています！`}
          imgs={[
            { id: 1, src: '/wazai/g1.jpg', alt: 'Image 10' },
            { id: 1, src: '/wazai/g2.jpg', alt: 'Image 11' },
            { id: 1, src: '/wazai/wazai101.webp', alt: 'Image 1' },
            { id: 2, src: '/wazai/wazai102.webp', alt: 'Image 2' },
            { id: 3, src: '/wazai/wazai103.webp', alt: 'Image 3' },
            { id: 4, src: '/wazai/wazai104.webp', alt: 'Image 4' },
            { id: 5, src: '/wazai/wazai105.webp', alt: 'Image 5' },
            { id: 6, src: '/wazai/wazai106.webp', alt: 'Image 6' },
            { id: 7, src: '/wazai/wazai107.webp', alt: 'Image 7' },
            { id: 8, src: '/wazai/wazai108.webp', alt: 'Image 8' },
            { id: 9, src: '/wazai/wazai109.webp', alt: 'Image 9' },
            { id: 10, src: '/wazai/wazai110.webp', alt: 'Image 10' },
          ]}
          sectionId="section4"
        />
        <FeatureSectionRight
          imageSrc="/wazai/wazai111.webp"
          imageAlt="木町店"
          title="デイサービス「和才屋」"
          description={`日田杉に囲まれた心地よい空間のデイサービス「和才屋」。

        団らん処「和菜屋」の食事を、小石原焼秀山窯の食器でお楽しみいただけます。
        
        利用者様の能力や個性に合わせた作業活動や、高齢者だからこそできる社会的な交流にも取り組んでいます。
        
        ご見学・ご体験はいつでも大歓迎です。`}
          sectionId="section2"
        />
        <FeatureSectionGridThree
          title="店舗の感染症対策"
          sectionId="section3"
          cords={[
            {
              id: 1,
              imageSrc: '/wazai/wazai112.webp',
              alt: 'Image 1',
              title: '店内入口にアルコール消毒液設置',
              description:
                'まず手指をしっかりと消毒してから、ご入店いただきます。',
            },
            {
              id: 3,
              imageSrc: '/wazai/wazai113.webp',
              imageAlt: 'Image 1',
              title: 'ウィルス除去空気清浄機設置',
              description:
                '浮遊ウイルスや浮遊菌を99％除去する空気清浄機を、各店２台ずつ設置しています。',
            },
            {
              id: 2,
              imageSrc: '/wazai/wazai114.webp',
              imageAlt: 'Image 1',
              title: '飛沫感染防止シート、パネル設置',
              description:
                'レジ前にビニールカーテン、イートインスペースにアクリルパネルを設置しています。',
            },
          ]}
        />
      </Layout>
    </div>
  );
};

export default WazaiTopPage;

export const Head = () => <Seo />;
