// extracted by mini-css-extract-plugin
export var content = "FeatureSection-module--content--0638d";
export var cord = "FeatureSection-module--cord--8b30d";
export var description = "FeatureSection-module--description--fc9ec";
export var featureSection = "FeatureSection-module--featureSection--0e73d";
export var featureSectionInner = "FeatureSection-module--featureSectionInner--12056";
export var featureSectionPrimary = "FeatureSection-module--featureSectionPrimary--d6354";
export var featureSectionSecondary = "FeatureSection-module--featureSectionSecondary--7e9a6";
export var grid = "FeatureSection-module--grid--4194b";
export var head = "FeatureSection-module--head--96c51";
export var headDescription = "FeatureSection-module--headDescription--c7ab4";
export var headSubtext = "FeatureSection-module--headSubtext--c2c0a";
export var headText = "FeatureSection-module--headText--cccd5";
export var image = "FeatureSection-module--image--20fce";
export var title = "FeatureSection-module--title--2ebf3";